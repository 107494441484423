import React, { Component } from "react";

import "./Settings.scss";

import UiAlert from "../../components/modals/UiAlert";
import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiSelect from "../../components/forms/UiSelect";
import UiSelectButton from "../../components/forms/UiSelectButton";
import UiSelectDate from "../../components/forms/UiSelectDate";
import UiSwitch from "../../components/forms/UiSwitch";
import UiTextInput from "../../components/forms/UiTextInput";

import Env from "../../services/Env";
import { loadUser } from "../../services/Auth";
import {
  getAll,
  update,
  getInterval,
  getTypeInterval, toggleInterval,
  getMinimalPriceToDay, addMinimalPriceToDay,
  editMinimalPriceToDay, getHistoryMinimalPriceToDay,
  getDeliveryPrices, editDeliveryPrice
} from "../../services/Settings";
import { getDayMonthYearName, getDayMonthName } from "../../components/common/Dates";
import {
  editTags,
  getTags
} from "../../services/Flower";

class SettingsPage extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    selectedDate: new Date(),
    selectedType: 0,
    selectedShop: "all",
    selectedDelivery: 0,
    minPrice: 0,

    tags: [],
    toggleArray: [],
    allDisabled: false,
    places: [
      { value: "all", option: "Вся сеть" },
      { value: "sadovaya", option: "Садовая" },
      { value: "vernadskogo", option: "Вернадского" },
      { value: "zuravleva", option: "Журавлева" },
    ],
    deliveryTypes: [
      { value: 0, option: "Доставка" },
      { value: 1, option: "Самовывоз" },

    ],
    intervalTypes: [
      { value: 0, option: "Дополнительные интервалы" },
      { value: 1, option: "Часовой интервал" },
      { value: 2, option: "Трехчасовой интервал" },
      { value: 3, option: "К точному времени" },
    ],
    available: [
      { value: 0, option: "Доступен" },
      { value: 1, option: "Не доступен" },
    ],
    intervalList: [],
    lastEvents: [],
    minPriceHistory: [],
    deliveryList: [],
    settings: {},

    /* modals */
    loader: false,
    isAlert: false,
  };

  componentDidMount() {
    this._load();
  }

  getDate(_date = new Date()) {
    var year, month, day;
    year = String(_date.getFullYear());
    month = String(_date.getMonth() + 1);
    if (month.length == 1) {
      month = "0" + month;
    }
    day = String(_date.getDate());
    if (day.length == 1) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  }


  getTitle(val, list) {
    let title = "";
    list.map((item) => {
      if (val == item.value) title = item.option;
    })
    return title;
  }

  _editTag(_title, _item, _val) {
    let item = _item;
    item['api_token'] = this.state.user.api_token;
    item[_title] = _val;
    editTags(item).then((res) => {
      this._load()
    });
  }

  _editDelivery(_index, _title, _val) {
    let item = this.state.deliveryList[_index]; 
    item[_title] = _val; 
  }

  async _updateDelivery() {
    this.setState({ loader: true })
    let data = await Promise.all(this.state.deliveryList.map((item) => {
      item['api_token'] = this.state.user.api_token;
      return editDeliveryPrice(item);
    }))

    this.setState({ loader: false });
  }

  _load() {
    let _user = loadUser();
    this.setState({
      user: _user,
      loader: true,
    });

    getTags().then((res) => {
      if (res) {
        this.setState({ tags: res.response })
      }
    })

    getTypeInterval(_user.api_token, this.getDate(this.state.selectedDate), this.state.selectedType, this.state.selectedShop, this.state.selectedDelivery).then((res) => {
      console.log(" getInterval ", res);
      this.setState({ allDisabled: false });
      res.response.list.map((item) => {
        if (item.enable) this.setState({ allDisabled: true });
      })
      this.setState({
        intervalList: res.response.list,
        lastEvents: res.response.history,
        loader: false
      });
    })

    getMinimalPriceToDay(_user.api_token, this.getDate(this.state.selectedDate)).then((res) => {
      console.log(" getMinimalPriceToDay ", res);
      if (res.response) {
        this.setState({
          minimalDeliveryPrice: res.response.price,
          minPrice: res.response.price,
          minPriceUpdateDate: res.response.updated_at,
        });
      } else {
        this.setState({
          minimalDeliveryPrice: 0,
          minPrice: 0,
        });
      }

    })

    getHistoryMinimalPriceToDay(_user.api_token, this.getDate(this.state.selectedDate)).then((res) => {
      console.log(" minPriceHistory ", res);
      if (res.response) {
        this.setState({
          minPriceHistory: res.response,
        });
      }

    })


    getAll().then((res) => {
      console.log(" getAll ", res);
      if (res.response) {
        this.setState({
          settings: res.response,
        });
      }

    })

    getDeliveryPrices().then((res) => {
      if (res.response) this.setState({ deliveryList: res.response })
    })


  }

  _switchAlert() {
    update({
      api_token: this.state.user.api_token,
      title: 'alert_title',
      value: this.state.alertTitleText
    }).then((res) => {
      console.log(res)
      this._load();
    })
    update({
      api_token: this.state.user.api_token,
      title: 'alert_btn',
      value: this.state.settings.alertBtnText
    }).then((res) => {
      console.log(res)
      this._load();
    })

    update({
      api_token: this.state.user.api_token,
      title: 'alert_active',
      value: this.state.settings.alert_active == 1 ? 0 : 1
    }).then((res) => {
      console.log(res)
      this._load();
    })
  }

  _switch(_id) {
    let arr = this.state.toggleArray;
    let f = false;
    let _index = 0;
    arr.map((item, index) => {
      if (item == _id) {
        f = true;
        _index = index;
      }
    })
    if (!f) arr.push(_id); else arr.splice(_index, 1)
  }

  _toggleAll(_flag) {
    let arr = [];
    this.state.intervalList.map((item, index) => {
      arr.push(item.id);
    })
    toggleInterval(this.state.user.api_token, this.getDate(this.state.selectedDate), arr, _flag ? "on" : "off").then((res) => {
      console.log(res)
      this.setState({ toggleArray: [] });
      this._load();
    })
  }

  _toggle() {
    toggleInterval(this.state.user.api_token, this.getDate(this.state.selectedDate), this.state.toggleArray, "single").then((res) => {
      console.log(res)
      this.setState({ toggleArray: [] });
      this._load();
    })
  }

  _saveMinPrice() {
    addMinimalPriceToDay(this.state.user.api_token, this.getDate(this.state.selectedDate), this.state.minPrice, 0).then((res) => {
      console.log(res)
      this.setState({ minPrice: 0 });
      this._load();
    })
  }

  render() {
    let intervalList = this.state.intervalList.map((item, index) => {
      return (
        <div className="interval-item" key={index}>
          <div className="interval-item-col">
            <UiSwitch callBack={() => this._switch(item.id)} value={item.enable} />
          </div>
          <div className="interval-item-col">
            <p>{item.date}</p>
          </div>
          <div className="interval-item-col">
            <p>{item.created_at}</p>
          </div>
          <div className="interval-item-col">
            <div className="interval-item-num">
              <p>{item.orders_day_count}</p>
            </div>
          </div>
        </div>
      );
    });

    let eventsList = this.state.lastEvents.map((item, index) => {
      return (
        <div className="event-item" key={index}>
          <p>
            вы установили на дату <strong>{getDayMonthName(item.date)}</strong> на витрине{" "}
            <strong>
              "{this.getTitle(item.shop, this.state.places)}" {item.interval_name == "all" ? `все ${this.getTitle(item.interval_type, this.state.intervalTypes)}` : `${this.getTitle(item.interval_type, this.state.intervalTypes) == "" ? "интервалы" : this.getTitle(item.interval_type, this.state.intervalTypes)} ${item.interval_name}`}
            </strong>{" "}
            {item.enable == 1
              ? "доступным"
              : "недоступным"}
          </p>
        </div>
      );
    });

    let minPriceList = this.state.minPriceHistory.map((item, index) => {
      return (
        <div className="event-item" key={index}>
          <p>
            вы установили на дату <strong>{getDayMonthName(item.date)}</strong> мин. сумму
            {` ${item.price}`}р
          </p>
        </div>
      );
    });


    let tagsList = this.state.tags.map((item, index) => {
      return (
        <div className="interval-item" key={index} >
          <div className="interval-item-col">
            <UiSwitch
              value={item.is_del == 0 ? true : false}
              callBack={(val) => {
                this._editTag("is_del", item, val == 0 ? 1 : 0)
              }} />

          </div>

          <div className="interval-item-col">
            <UiTextInput
              placeholder={item.title}
              small
              onBlur={(val) => this._editTag("title", item, val)}
            />
          </div>
          <div className="interval-item-col">
            <UiTextInput
              placeholder={item.max}
              small
              onBlur={(val) => this._editTag("max", item, val)}
            />
          </div>
          <div className="interval-item-col">
            <UiTextInput
              placeholder={item.sort}
              small
              onBlur={(val) => this._editTag("sort", item, val)}
            />
          </div>

        </div>
      )
    })

    let priceList = this.state.deliveryList.map((item, index) => {
      return (
        <div className="interval-item-3" key={index} >


          <div className="interval-item-col">
            <p>{item.description}</p>
          </div>

          <div className="interval-item-col">

            {item.has_distance != 1 ?
              <div>
                <UiTextInput
                  placeholder={item.price}
                  small
                  onBlur={(val) => this._editDelivery(index, "price",val)}
                />
              </div>
              :
              <div className="input-distance-wrap">
                <UiTextInput
                  placeholder={item.price}
                  small
                  onBlur={(val) => this._editDelivery(index, "price", val)}
                />
                +
                <UiTextInput
                  placeholder={item.distance_price}
                  small
                  onBlur={(val) => this._editDelivery(index, "distance_price", val)}
                />
                * km
              </div>
            }

          </div>

          <div className="interval-item-col">
            <UiSwitch
              value={item.is_active == 1}
              callBack={(val) => {
                this._editDelivery(index,"is_active", val)
              }} />

          </div>

        </div>
      )
    })



    return (
      <div ref={this.divPage} id="page">
        <UiHeader page="settings" />
        <div className="content">
          <div className="section">
            <div className="settings-wrap">
              <h2>Настройки магазина:</h2>
              <div className="settings-item">
                <h3>Ограничение заказов по интервалам</h3>
                <div className="settings-item-params">
                  <UiSelectDate
                    label="ВЫБЕРИТЕ ДАТУ"
                    onChange={(val) => {
                      this.setState({ selectedDate: val }, () => this._load())
                    }}
                  />
                  <UiSelect
                    label="ВЫБЕРИТЕ ВИТРИНУ"
                    optionList={this.state.places}
                    onChange={(val) => {
                      this.setState({ selectedShop: val }, () => this._load())
                    }}
                  />
                  <UiSelect
                    label="ТИП ДОСТАВКИ"
                    optionList={this.state.deliveryTypes}
                    onChange={(val) => {
                      this.setState({ selectedDelivery: val }, () => this._load())
                    }}
                  />
                  <UiSelect
                    label="ТИП ИНТЕРВАЛА"
                    optionList={this.state.intervalTypes}
                    onChange={(val) => {
                      this.setState({ selectedType: val }, () => this._load())
                    }}
                  />
                  <UiSelectButton label="СДЕЛАТЬ НЕДОСТУПНЫМ" buttonText="Выключить все" pink onClick={() => this._toggleAll(true)} />
                  <UiSelectButton label="СДЕЛАТЬ ДОСТУПНЫМ" buttonText="Включить все" green onClick={() => this._toggleAll(false)} />

                </div>
                <div className="settings-item-wrap">
                  <div className="settings-item-control">
                    <div className="settings-item-control-title">
                      <div className="settings-item-control-status">
                        <p>Статус</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Временной интервал</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Дата активации</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Количество заказов</p>
                      </div>
                    </div>
                    {intervalList}
                    <div className="settings-item-save">
                      <UiButtonOrange buttonText="Применить" small green onClick={() => this._toggle()} />
                    </div>
                  </div>
                  <div className="settings-item-info">
                    <h4>Журнал событий</h4>
                    {eventsList}
                  </div>
                </div>
              </div>

              <div className="settings-item">
                <h3>Ограничение минимального заказа в доставке</h3>

                <div className="settings-item-wrap">
                  <div className="settings-item-control">
                    <div className="settings-item-control-title-3">

                      <div className="settings-item-control-status">
                        <p>Дата</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Дата активации</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Минимальная сумма</p>
                      </div>
                    </div>

                    <div className="interval-item-3"  >
                      <div className="interval-item-col">
                        <p>{this.getDate(this.state.selectedDate)}</p>
                      </div>
                      <div className="interval-item-col">
                        <p>{this.state.minPriceUpdateDate}</p>
                      </div>
                      <div className="interval-item-col">

                        <UiTextInput
                          placeholder={this.state.minimalDeliveryPrice}
                          small
                          onChange={(val) => this.setState({ minPrice: val })}
                        />

                      </div>
                    </div>

                    <div className="settings-item-save">
                      <UiButtonOrange buttonText="Применить" small green onClick={() => this._saveMinPrice()} />
                    </div>
                  </div>
                  <div className="settings-item-info">
                    <h4>Журнал событий</h4>
                    {minPriceList}
                  </div>
                </div>
              </div>

              <div className="settings-item">
                <h3>Всплывающие окно</h3>
                <div className="settings-item-wrap">
                  <div className="settings-item-control">
                    <div className="settings-item-control-title-3">
                      <div className="settings-item-control-status">
                        <p>Сообщение</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Кнопка</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p> </p>
                      </div>
                    </div>
                    <div className="interval-item-3"  >
                      <div className="interval-item-col">
                        <UiTextInput
                          placeholder={this.state.settings.alert_title}
                          small
                          onChange={(val) => this.setState({ alertTitleText: val })}
                        />
                      </div>
                      <div className="interval-item-col">
                        <UiTextInput
                          placeholder={this.state.settings.alert_btn}
                          small
                          onChange={(val) => this.setState({ alertBtnText: val })}
                        />
                      </div>
                      <div className="interval-item-col">
                        <UiSwitch callBack={() => {
                          this._switchAlert();
                        }} value={this.state.settings.alert_active == 1} />

                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div className="settings-item">
                <h3>Управление каруселями</h3>
                <div className="settings-item-wrap">
                  <div className="settings-item-control">

                    <div className="settings-item-control-title">
                      <div className="settings-item-control-status">
                        <p>Статус</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Заголовок</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Элементов </p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Сортировка </p>
                      </div>

                    </div>
                    {tagsList}
                  </div>
                </div>
              </div>


              <div className="settings-item">
                <h3>Всплывающие окно</h3>
                <div className="settings-item-wrap">
                  <div className="settings-item-control">
                    <div className="settings-item-control-title-3">
                      <div className="settings-item-control-status">
                        <p>Тип доставки</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p>Значение (руб)</p>
                      </div>
                      <div className="settings-item-control-status">
                        <p> Активность</p>
                      </div>
                    </div>
                    {priceList}
                    <div className="settings-item-save">
                      <UiButtonOrange buttonText="Применить" small green onClick={() => this._updateDelivery()} />
                    </div>
                  </div>
                </div>
              </div>





            </div>
          </div>
          <UiFooter />
        </div>

        {/* modals */}
        <UiLoader modalOpen={this.state.loader} />

        <UiAlert
          modalOpen={this.state.isAlert}
          title="Внимание!"
          text="Вы не сможете отменить удаление. Продолжить?"
          onClick={() => this.remove()}
          cancelClick={() => this.setState({ isAlert: false })}
        />
      </div>
    );
  }
}

export default SettingsPage;
